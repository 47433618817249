import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Layout } from "../components/layout/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Slides } from "../components/hero/Slides";
import { Button } from "../components/ui/Button";
import Slider from "react-slick";
import {
  MdChevronLeft as PrevArrow,
  MdChevronRight as NextArrow,
} from "react-icons/md";

const LaCantina = ({ data }) => {
  const { wpSezione } = data;
  const { title, content, laCantina } = wpSezione;
  const { herogallery, description, gallery, opacity } = laCantina;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <PrevArrow
      color={"white"}
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      Previous
    </PrevArrow>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <NextArrow
      color={"white"}
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      Next
    </NextArrow>
  );

  const winesSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft currentSlide={0} slideCount={0} />,
    nextArrow: <SlickArrowRight currentSlide={0} slideCount={0} />,
  };

  return (
    <main>
      <Helmet title={`${title} - Ristorante A Mangiare`} defer={false} />
      <Layout headerPosition="position-absolute" className="bg-white">
        <Slides
          title={title}
          content={content}
          opacity={opacity}
          images={herogallery}
        />
        <div className="bg-tertiary">
          <div className="container pt-5 pb-5">
            <div className="text-center">
              <h2>Scopri la nostra</h2>
              <a
                href="https://qr.carta-dei-vini.it/index.html?qrcode=AD61AB143223EFBC24C7D2583BE69251#/wines"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="bg-primary text-white m-2">Cantina</Button>
              </a>
              <div className="bg-white p-4 mt-5 mb-2">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-secondary">
          <div className="container pt-5 pb-5">
            <Slider {...winesSettings}>
              {gallery.map((image, index) => (
                <div className="p-3 p-lg-5" key={index}>
                  <GatsbyImage
                    loading="eager"
                    image={image.localFile.childImageSharp.gatsbyImageData}
                    alt={index.toString()}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Layout>
    </main>
  );
};

export default LaCantina;

export const data = graphql`
  query {
    wpSezione(slug: { eq: "la-cantina" }) {
      title
      content
      laCantina {
        herogallery {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        description
        opacity
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
